export default {
  async selectLsolicitudMaterial (Vue, idlsolicitudMaterial) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.lsolicitud_material)
      .where(
        Vue.$offline.db.op.and(
          tables.lsolicitud_material.idlsolicitud_material.eq(idlsolicitudMaterial),
          tables.lsolicitud_material.estado.gt(0),
        )
      )
      .exec())[0]
  },
}
